import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

function Services() {
  const tarifs = [
    { name: 'Разовый', hours: 1, sum: 2200, price: 2200 },
    { name: 'Базовый', hours: 2, sum: 3800, price: 1900 },
    { name: 'Стандарт', hours: 3, sum: 5400, price: 1800 },
    { name: 'Бизнес', hours: 5, sum: 8500, price: 1700 },
  ];
  //console.log(tarifs);
  const data = useStaticQuery(graphql`
    query {
      allContentfulServiceCard {
        edges {
          node {
            title
            slug
            longDescription {
              internal {
                content
              }
              childMarkdownRemark {
                html
              }
            }
            icon {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO
        keywords={[
          `1С`,
          `Сопровождение 1С`,
          `Сервисы 1С`,
          `1С:ИТС`,
          `софтмакс`,
          `1с Пыть-Ях`,
        ]}
        title='О компании'
      />

      <section className='flex flex-col items-center '>
        <h2 className='text-2xl p-4 text-center'>
          Сопровождение 1С (ИТС) - основное направление нашей деятельности.
        </h2>
        <div id='group' className=' px-2 flex flex-wrap'>
          <div className=' md:flex-1 m-1  bg-gray-200 py-2 px-3 text-lg rounded tracking-wider'>
            <p>
              Мы и наши клиенты отдаем предпочтение абонентским договорам с
              фиксированной ежемесячной платой.
              <br /> Такой подход обеспечивает заказчику легко прогнозируемые
              затраты, а исполнителя мотивирует к тому, чтобу у клиента все
              работало «как часы» и чтобы клиент был доволен.
            </p>
            {/* <hr className='my-2 border-gray-500' />
            <p>
              При одновременном обращении к нам клиента, который пользуется
              нашими услугами периодически и клиента-абонента, мы отдаем
              предпочтение всегда последнему.
            </p> */}
          </div>
          {/* <div className='m-1 flex-1 bg-white text-center flex justify-center'> */}
          <table className='md:flex-1 flex-auto'>
            <thead className=' font-bold bg-blue-100 text-center'>
              <tr>
                <td className='py-4 px-8 border uppercase'>Тариф</td>
                <td className='py-4 border uppercase'>Час/мес.</td>
                <td className='py-4 border uppercase'>Сумма</td>
                <td className='py-4 border uppercase'>Цена часа</td>
              </tr>
            </thead>
            <tbody className='text-center'>
              {tarifs.map((tarif, idx) => (
                <tr
                  key={tarif.name}
                  className={idx % 2 !== 0 ? 'bg-blue-100 ' : 'text-blue-700'}
                >
                  <td className='py-4 border font-bold'>{tarif.name}</td>
                  <td className='py-4 border'>{tarif.hours}</td>
                  <td className='py-4 border'>{tarif.sum}</td>
                  <td className='py-4 border'>{tarif.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <p className='text-lg  font-bold mt-6 text-center'>
          Что значит аббревиатура ИТС? - Информационно-Технологическое
          Сопровождение!
        </p>
        <p className='text-base mt-2 text-center'>
          ИТС - это целый комплекс услуг и сервисов предоставляемый Фирмой 1С и
          ее партнерами
        </p>
        <h3 className='text-xl text-2xl font-bold text-red-700 uppercase my-6 tracking-wider'>
          Сервисы ИТС
        </h3>
        <div className='flex flex-wrap justify-center rela'>
          {data.allContentfulServiceCard.edges.map((edge) => {
            //console.log(edge);
            return (
              <Link
                to={`/services/${edge.node.slug}`}
                key={edge.node.title}
                className='flex-1 flex-auto sm:w-1/3 lg:w-1/4 relative w-88 m-2 px-4 py-2 bg-gray-100 hover:bg-white shadow hover:shadow-lg border rounded'
              >
                <div className='flex'>
                  <img
                    className='absolute tl-5 bg-orange-500 rounded w-12 h-12'
                    src={edge.node.icon.file.url}
                    alt='image'
                  />

                  <h2 className='ml-20 mt-2 text-xl font-bold'>
                    {edge.node.title}
                  </h2>
                </div>
                <div
                  className='mt-4 text-sm'
                  dangerouslySetInnerHTML={{
                    //__html: edge.node.longDescription.internal.content
                    __html: edge.node.longDescription.childMarkdownRemark.html,
                  }}
                />
              </Link>
            );
          })}
        </div>
      </section>
    </Layout>
  );
}

export default Services;
